import React, { useState, useLayoutEffect } from "react";
import ReactMarkdown from "react-markdown";

import Info from "@templates/Info";
import ContentBox from "@atoms/ContentBox";
import Icon from "@atoms/Icon";
import Text from "@atoms/Text";

import { termsOfUse } from "@content/termsOfUse";
import TextSvg from "@images/icons/file-text.svg";

const TermsPage = () => {
  const [startPage, setStartPage] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setStartPage(true), 500);
  }, [setStartPage]);

  return (
    <Info active={startPage}>
      <ContentBox>
        <h2>
          <Icon src={TextSvg} /> Terms and conditions
        </h2>
      </ContentBox>
      <ContentBox>
        <Text>
          <ReactMarkdown children={termsOfUse.content} />
        </Text>
      </ContentBox>
    </Info>
  );
};

export default TermsPage;
